// NPM
import * as React from "react"
import { graphql } from 'gatsby'
import _ from 'lodash'

// Components
import Layout from "src/layouts/en"
import Contact from "src/components/pages/Contact/Contact";

const ContactPageEn = ({ location, data }) => {
  const topNav = data.topNav
  const sections = _.get(data, `contactPage.sections`, [])
  const contactForm = _.get(data, `contactForm`)
  const testimonials = _.get(data, `testimonials.nodes`, [])
  const bottomNav = data.bottomNav
  const socialMedia = _.get(data, `socialMedia.nodes`, [])

  return (
    <Layout 
      location={ location }
      topNav={ topNav }
      page={ data.contactPage }
      bottomNav={ bottomNav }
      socialMedia={ socialMedia }>
        <Contact 
          sections={ sections } 
          contactForm={ contactForm }
          testimonials={ testimonials }
        />
    </Layout>
  )
}

export default ContactPageEn

export const query = graphql`
  query ContactPageEnQuery($id: String) {
    topNav: strapiTopNav(locale: {eq: "en"}) {
      ...topNavContent
    }
    
    contactPage: strapiPage(id: {eq: $id}) {
      ...pageInformation
    }

    contactForm: strapiContactForm {
      fields: strapi_fields {
        uuid
        strapi_component
        placeholder
        order
        name
        options {
          label
          order
          value
        }
      }

      submitText
    }

    testimonials: allStrapiTestimonial(filter: {locale: {eq: "en"}}) {
      nodes {
        name
        quote
        credit
        order
      }
    }

    bottomNav: strapiBottomNav(locale: {eq: "en"}) {
      ...bottomNavContent
    }

    socialMedia: allStrapiSocialMedia(filter: {locale: {eq: "en"}}) {
      nodes {
        ...socialMedia
      }
    }

  }
`